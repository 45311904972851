<template>
    <div v-loading="loading.get_campaign">
        <!--        <pre>{{ campaign_detail }}</pre>-->

        <div class="p-[20px]">
            <div class="flex gap-[20px] items-center">
                <strong class="text-[20px]">
                    {{ campaign_detail.campaign_name }}
                </strong>

                <el-tag type="success">{{ $t('common.active') }}</el-tag>
            </div>

            <div class="mt-[10px] text-[14px]">
                <div class="flex gap-[30px]">
                    <div class="flex gap-[8px]">
                        <div class="text-[#0000008C]">ID</div>
                        <div>{{ campaign_detail.campaign_id }}</div>
                    </div>

                    <div class="flex gap-[5px]">
                        <div class="text-[#0000008C]">{{ $t('gmv_max.target_roi') }}</div>
                        <div>{{ campaign_detail.roas_bid.toFixed(2) }}</div>
                    </div>

                    <div class="flex gap-[5px]">
                        <div class="text-[#0000008C]">{{ $t('page.campaign.schedule_time') }}</div>
                        <div>{{ campaign_detail.schedule_start_time }}</div>
                    </div>
                </div>
            </div>
            <div class="mt-[5px] text-[14px]">
                <div class="flex gap-[30px]">
                    <div class="flex gap-[8px]">
                        <div class="text-[#0000008C]">{{ $t('gmv_max.budget_roi') }}</div>
                        <div>{{ p__formatCurrency(campaign_detail.budget) }}</div>
                    </div>

                    <div class="flex gap-[5px]">
                        <div class="text-[#0000008C]">{{ $t('gmv_max.budget_target') }}</div>
                        <div>{{ '-' }}</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { gmvMaxGetCampaignDetail } from '@/services/atosa-tiktok-ads/gmv_max'

export default {
    name: 'GmvMaxCampaignDetail',

    props: {
        ads_account: null,
        tiktok_shop: null,
        campaign: {}
    },

    data() {
        return {
            loading: {
                get_campaign: false
            },
            campaign_detail: {}
        }
    },

    async mounted() {
        await this.callGetGmvMaxCampaignDetail()
    },

    methods: {
        async callGetGmvMaxCampaignDetail() {
            this.loading.get_campaign = true

            const tiktok_account_id = this.$store.getters.tiktokBusiness.s__selected_tiktok_business.id
            const params = {
                advertiser_id: this.ads_account.advertiser_id,
                campaign_id: this.campaign.campaign_id
            }

            const response = await gmvMaxGetCampaignDetail(tiktok_account_id, params)
            // console.log(response)
            this.campaign_detail = response.data.data

            this.loading.get_campaign = false
        }
    }
}
</script>
