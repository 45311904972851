import request from './_config'

const path = `gmv-max`

export function gmvMaxGetTiktokShop(tiktok_account_id, params) {
    return request({
        url: `${path}/tiktok_shops/${tiktok_account_id}`,
        method: 'get',
        params
    })
}

export function gmvMaxGetRecommendedGmv(tiktok_account_id, params) {
    return request({
        url: `${path}/recommended_gmv/${tiktok_account_id}`,
        method: 'get',
        params
    })
}

export function gmvMaxCheckAvailabilityTiktokShop(tiktok_account_id, params) {
    return request({
        url: `${path}/check_availability_tiktok_shop/${tiktok_account_id}`,
        method: 'get',
        params
    })
}

export function gmvMaxCreateCampaign(tiktok_account_id, data) {
    return request({
        url: `${path}/create_campaign/${tiktok_account_id}`,
        method: 'post',
        data
    })
}

export function gmvMaxUpdateCampaign(tiktok_account_id, data) {
    return request({
        url: `${path}/update_campaign/${tiktok_account_id}`,
        method: 'post',
        data
    })
}

export function gmvMaxGetIdentities(tiktok_account_id, params) {
    return request({
        url: `${path}/identities/${tiktok_account_id}`,
        method: 'get',
        params
    })
}

export function gmvMaxGetTiktokShopProducts(tiktok_account_id, params) {
    return request({
        url: `${path}/get_products/${tiktok_account_id}`,
        method: 'get',
        params
    })
}

export function gmvMaxGetCampaignDetail(tiktok_account_id, params) {
    return request({
        url: `${path}/details/${tiktok_account_id}`,
        method: 'get',
        params
    })
}

export function gmvMaxGetCampaigns(tiktok_account_id, params) {
    return request({
        url: `${path}/get_campaigns/${tiktok_account_id}`,
        method: 'get',
        params
    })
}

export function gmvMaxGetReport(tiktok_account_id, params) {
    return request({
        url: `${path}/get_report/${tiktok_account_id}`,
        method: 'get',
        params
    })
}
