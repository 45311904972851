<template>
    <div>
        <div>
            <div class="flex gap-[20px] items-center">
                <div>
                    <el-button size="medium" icon="el-icon-back" @click="$emit('back_to_list', false)"></el-button>
                </div>
                <div>
                    <strong v-if="campaign" class="text-black text-[28px]">
                        {{ $t('button.update_campaign') }}
                    </strong>
                    <strong v-else class="text-black text-[28px]">
                        {{ $t('button.create_campaign') }}
                    </strong>
                </div>
            </div>
        </div>

        <div class="flex mt-[20px]">
            <div class="flex-1 max-w-[725px]">
                <div>
                    <div class="flex gap-[15px]">
                        <div
                            class="flex-1 py-[12px] px-[16px] bg-white border border-[#00000024] flex gap-[10px] rounded-[4px] cursor-pointer"
                            @click="(!campaign) && (shopping_ads_type = 'PRODUCT')"
                        >
                            <div>
                                <el-radio
                                    v-model="shopping_ads_type"
                                    label="PRODUCT"
                                    class="hide-el-radio-label"
                                    :disabled="!!campaign"
                                ></el-radio>
                            </div>
                            <div>
                                <div>
                                    <strong class="text-[16px]">
                                        {{ $t('gmv_max.product_gmv_max') }}
                                    </strong>
                                </div>
                                <div class="text-[12px] text-[#0000008C]">
                                    {{ $t('gmv_max.product_gmv_max_note') }}
                                </div>
                            </div>
                        </div>

                        <div
                            class="flex-1 py-[12px] px-[16px] bg-white border border-[#00000024] flex gap-[10px] rounded-[4px] cursor-pointer"
                            @click="(!campaign) && (shopping_ads_type = 'LIVE')"
                        >
                            <div>
                                <el-radio
                                    v-model="shopping_ads_type"
                                    label="LIVE"
                                    class="hide-el-radio-label"
                                    :disabled="!!campaign"
                                ></el-radio>
                            </div>
                            <div>
                                <div>
                                    <strong class="text-[16px]">
                                        {{ $t('gmv_max.live_gmv_max') }}
                                    </strong>
                                </div>
                                <div class="text-[12px] text-[#0000008C]">
                                    {{ $t('gmv_max.live_gmv_max_note') }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="mt-[20px] box-border">
                    <div class="bg-white rounded-[4px] py-[12px] px-[16px] pt-[20px]">
                        <div v-if="shopping_ads_type === 'PRODUCT'">
                            <div>
                                <strong class="text-[18px] text-black">
                                    {{ $t('common.product') }}
                                </strong>
                            </div>

                            <div class="flex justify-between items-center mt-[20px]">
                                <div class="flex items-center gap-[10px]">
                                    <el-switch
                                        v-model="promote_all_products"
                                        :disabled="cannot_promote_all_products || !!campaign"
                                    ></el-switch>
                                    <div class="text-[14px]">
                                        {{ $t('gmv_max.promote_all_products') }}
                                    </div>
                                </div>
                                <div>
                                    <el-button
                                        size="small"
                                        icon="el-icon-plus"
                                        :disabled="promote_all_products || !!campaign"
                                        @click="show_dialog.select_product =true"
                                    >
                                        {{ $t('page.campaign.add_products') }}
                                    </el-button>
                                </div>
                            </div>

                            <div v-if="selected_product_list.length > 0" class="mt-[20px]">
                                <div class="h-[56px] p-[16px] bg-[#f5f5f5] flex justify-between items-center">
                                    <div>
                                        {{ $t('gmv_max.product_list') }}
                                        ({{ $t('gmv_max.selected_x_products', { x: selected_product_list.length }) }})
                                    </div>
                                    <div>
                                        <el-button
                                            type="text"
                                            :disabled="!!campaign"
                                            @click="selected_product_list = []"
                                        >
                                            <strong
                                                class="text-[16px] text-[#009995] cursor-pointer"
                                            >
                                                {{ $t('gmv_max.clear_all') }}
                                            </strong>
                                        </el-button>
                                    </div>
                                </div>
                                <div
                                    v-for="(product, index) in selected_product_list"
                                    :key="product.item_group_id"
                                    class="h-[56px] flex gap-[20px] items-center py-[12px] px-[16px]"
                                >
                                    <div class="w-[40px]">
                                        <img
                                            :src="product.product_image_url"
                                            alt=""
                                            class="w-[40px] h-[40px] object-cover"
                                        >
                                    </div>
                                    <div class="flex-1">
                                        <div class="text-[16px]">
                                            {{ product.title }}
                                        </div>
                                        <div class="text-[13px] text-[#0000008C]">
                                            ID: {{ product.item_group_id }}
                                        </div>
                                    </div>
                                    <div class="w-[40px] flex items-center justify-center">
                                        <i
                                            class="el-icon-close text-[14px] cursor-pointer"
                                            @click="selected_product_list.splice(index, 1)"
                                        ></i>
                                    </div>
                                </div>
                            </div>

                            <div
                                v-if="cannot_promote_all_products"
                                class="mt-[20px] rounded-[4px] flex gap-[10px] bg-[#E19F0B26] py-[8px] px-[16px]"
                            >
                                <div>
                                    <svg
                                        class="theme-arco-icon theme-arco-icon-Warning "
                                        width="1em"
                                        height="1em"
                                        viewBox="0 0 16 16"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M.427 13.254L7.566.76a.5.5 0 01.868 0l7.139 12.494a.5.5 0 01-.434.748H.862a.5.5 0 01-.435-.748z"
                                            fill="#E19F0B"
                                        ></path>
                                        <path
                                            fill-rule="evenodd"
                                            clip-rule="evenodd"
                                            d="M8.76 5.354H7.24l.123 4h1.24l.157-4zM8 12.002a.846.846 0 100-1.693.846.846 0 000 1.693z"
                                            fill="#fff"
                                        ></path>
                                    </svg>
                                </div>
                                <div class="text-[14px]">
                                    {{ $t('gmv_max.cannot_promote_all_products') }}
                                </div>
                            </div>
                        </div>

                        <div v-if="shopping_ads_type === 'LIVE'">
                            <div>
                                <strong class="text-[18px] text-black">
                                    {{ $t('gmv_max.live_source') }}
                                </strong>
                            </div>
                            <div class="text-[12px] text-[#0000008C]">
                                {{ $t('gmv_max.select_live_source') }}
                            </div>

                            <div class="mt-[15px]">
                                <el-select
                                    v-model="selected_identity_id"
                                    size="small"
                                    class="w-full"
                                    :disabled="!!campaign"
                                >
                                    <el-option
                                        v-for="identity in identity_list"
                                        :key="identity.identity_id"
                                        :value="identity.identity_id"
                                        :label="`${identity.display_name} - ${identity.identity_id}`"
                                    >
                                    </el-option>
                                </el-select>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="mt-[20px] box-border">
                    <div class="bg-white rounded-[4px] py-[12px] px-[16px] pt-[20px]">
                        <div v-if="shopping_ads_type === 'PRODUCT'">
                            <div>
                                <strong class="text-[18px] text-black">
                                    {{ $t('gmv_max.optimization_and_budget') }}
                                </strong>
                            </div>
                        </div>

                        <div class="mt-[15px]">
                            <strong class="text-[16px]">
                                {{ $t('gmv_max.optimization_goal') }}
                            </strong>
                        </div>

                        <div class="mt-[15px]">
                            <div>
                                <strong class="text-[14px]">
                                    {{ $t('gmv_max.group_budget') }}
                                </strong>
                            </div>
                            <div>
                                <strong class="text-[12px] text-[#0000008C]">
                                    {{ $t('gmv_max.group_budget_note') }}
                                </strong>
                            </div>
                        </div>

                        <div class="my-[30px] border-b border-b-[#0000001A]">
                        </div>

                        <div class="mt-[20px]">
                            <div>
                                <strong class="text-[16px]">
                                    <template v-if="shopping_ads_type === 'PRODUCT'">
                                        {{ $t('gmv_max.product_target_roi') }}
                                    </template>
                                    <template v-if="shopping_ads_type === 'LIVE'">
                                        {{ $t('gmv_max.live_target_roi') }}
                                    </template>
                                </strong>
                            </div>
                            <div class="mt-[10px]">
                                <el-input v-model="roas_bid" type="number" size="medium"></el-input>
                            </div>
                        </div>

                        <div class="my-[30px] border-b border-b-[#0000001A]">
                        </div>

                        <div class="mt-[20px]">
                            <div>
                                <strong class="text-[16px]">
                                    {{ $t('common.daily_budget') }}
                                </strong>
                            </div>
                            <div class="mt-[10px]">
                                <el-input v-model="budget" type="number" size="medium"></el-input>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="mt-[20px] box-border">
                    <div class="bg-white rounded-[4px] py-[12px] px-[16px] pt-[20px]">
                        <div>
                            <strong class="text-[18px] text-black">
                                {{ $t('common.schedule') }}
                            </strong>
                        </div>
                        <div class="mt-[15px]">
                            <el-date-picker
                                v-model="start_time"
                                type="datetime"
                                class="!w-full"
                                size="small"
                                :disabled="!!campaign"
                            >
                            </el-date-picker>
                        </div>
                        <div>
                            <div class="text-[12px] text-[#0000008C]">
                                {{ $t('gmv_max.schedule_note') }}
                            </div>
                        </div>

                        <div class="mt-[15px]">
                            <div>
                                <el-checkbox v-model="allow_end_time" size="small">
                                    {{ $t('gmv_max.set_end_time') }}
                                </el-checkbox>
                            </div>
                        </div>
                        <div v-if="allow_end_time" class="mt-[5px]">
                            <el-date-picker
                                v-model="end_time"
                                type="datetime"
                                class="!w-full"
                                size="small"
                            >
                            </el-date-picker>
                        </div>
                    </div>
                </div>

                <div class="mt-[20px] box-border">
                    <div class="bg-white rounded-[4px] py-[12px] px-[16px] pt-[20px]">
                        <div>
                            <strong class="text-[18px] text-black">
                                {{ $t('page.campaign.campaign_name') }}
                            </strong>
                        </div>
                        <div class="mt-[15px]">
                            <el-input
                                v-model="campaign_name"
                                size="small"
                                :placeholder="$t('page.campaign.please_input_campaign_name')"
                            ></el-input>
                        </div>
                    </div>
                </div>

                <div class="mt-[20px] box-border">
                    <div class="bg-white rounded-[4px] py-[12px] px-[16px] pt-[20px]">
                        <div class="flex justify-between">
                            <div></div>
                            <div class="text-right">
                                <el-button>{{ $t('button.cancel') }}</el-button>
                                <el-button
                                    v-if="!!campaign"
                                    type="primary"
                                    :loading="loading.update_campaign"
                                >
                                    {{ $t('button.edit') }}
                                </el-button>
                                <el-button
                                    v-else
                                    type="primary"
                                    :loading="loading.create_campaign"
                                    @click="callGmvMaxCreateCampaign()"
                                >
                                    {{ $t('button.publish') }}
                                </el-button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <el-dialog
            width="500px"
            :close-on-click-modal="false"
            :visible.sync="show_dialog.create_success"
            :show-close="false"
            custom-class="no-body"
        >
            <div slot="default" class="text-center">
                <div>
                    <strong class="text-[18px]">
                        {{ $t('gmv_max.campaign_published') }}
                    </strong>
                </div>
                <div class="mt-[15px] text-[14px] text-[#0000008C]">
                    {{ $t('gmv_max.campaign_published_note') }}
                </div>
                <div class="mt-[30px]">
                    <el-button type="primary" size="small" @click="$emit('back_to_list', true)">
                        {{ $t('gmv_max.access_to_campaign_list') }}
                    </el-button>
                </div>
            </div>
        </el-dialog>

        <el-drawer
            :visible.sync="show_dialog.select_product"
            :title="$t('gmv_max.select_products')"
            size="50%"
        >
            <div slot="title">
                <div>
                    <strong class="text-[20px] text-black">
                        {{ $t('gmv_max.select_products') }}
                    </strong>
                </div>
            </div>
            <div slot="default">
                <div class="px-[20px]">
                    <div class="flex gap-[10px] flex-4">
                        <el-input
                            v-model="product_filter.search_keyword"
                            size="small"
                            prefix-icon="el-icon-search"
                            class="flex-[4]"
                        ></el-input>
                        <el-select v-model="product_filter.status" size="small" class="flex-[2]">
                            <el-option value="AVAILABLE" :label="$t('common.stock')"></el-option>
                            <el-option value="ALL" :label="$t('common.all')"></el-option>
                        </el-select>
                        <div class="flex-[2.5]"></div>
                        <!--                        <el-select size="small" class="flex-[2.5]"></el-select>-->
                    </div>

                    <div class="mt-[20px]">
                        <el-table :data="c__product_filter_list" height="calc(100vh - 220px)">
                            <el-table-column label="#" width="40px" fixed="left">
                                <template slot="header" slot-scope="scope">
                                    <el-checkbox
                                        size="mini"
                                        :value="c__is_selected_all_products"
                                        :indeterminate="c__is_indeterminate_products"
                                        @change="onSelectedAllProducts"
                                    ></el-checkbox>
                                </template>
                                <template slot-scope="scope">
                                    <el-checkbox v-model="scope.row.selected" size="mini"></el-checkbox>
                                </template>
                            </el-table-column>
                            <el-table-column
                                :label="$t('common.product_name')"
                                width="300px"
                                fixed="left"
                            >
                                <template slot-scope="scope">
                                    <div class="flex items-center gap-[10px]">
                                        <div class="w-[40px] flex items-center">
                                            <img
                                                :src="scope.row.product_image_url"
                                                alt=""
                                                class="w-[40px] h-[40px] object-cover"
                                            >
                                        </div>
                                        <div class="flex-1">
                                            <div>
                                                {{ scope.row.title }}
                                            </div>
                                            <div class="text-[12px] text-[#0000008C]">
                                                ID: {{ scope.row.item_group_id }}
                                            </div>
                                        </div>
                                    </div>
                                </template>
                            </el-table-column>
                            <el-table-column :label="$t('common.product_price')" width="120px">
                                <template slot-scope="scope">
                                    <template v-if="scope.row.min_price === scope.row.max_price">
                                        {{ p__formatCurrency(scope.row.min_price) }} {{ scope.row.currency }}
                                    </template>
                                    <template v-else>
                                        {{ p__formatCurrency(scope.row.min_price) }} -
                                        {{ p__formatCurrency(scope.row.max_price) }}
                                        {{ scope.row.currency }}
                                    </template>
                                </template>
                            </el-table-column>
                            <el-table-column
                                :label="$t('common.historical_sales')"
                                min-width="120px"
                                prop="historical_sales"
                                sortable
                            >
                                <template slot-scope="scope">
                                    {{ p__formatCurrency(scope.row.historical_sales) }}
                                </template>
                            </el-table-column>
                            <!--                            <el-table-column :label="$t('common.product_stock')" width="120px"></el-table-column>-->
                            <!--                            <el-table-column :label="$t('common.updated_time')" width="120px"></el-table-column>-->
                        </el-table>
                    </div>

                    <div class="mt-[30px]">
                        <div class="flex justify-between items-center">
                            <div class="text-[14px]">
                                {{ $t('gmv_max.selected_x_products', { x: c__count_selected_products }) }}
                            </div>
                            <div>
                                <el-button size="small" @click="show_dialog.select_product = false">
                                    {{ $t('button.cancel') }}
                                </el-button>
                                <el-button size="small" type="primary" @click="confirmSelectProducts()">
                                    {{ $t('button.confirm') }}
                                </el-button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </el-drawer>
    </div>
</template>

<script>
import { pick as _pick } from 'lodash'
import moment from 'moment'
import {
    gmvMaxCheckAvailabilityTiktokShop,
    gmvMaxCreateCampaign, gmvMaxGetCampaignDetail, gmvMaxGetIdentities,
    gmvMaxGetRecommendedGmv, gmvMaxGetTiktokShopProducts
} from '@/services/atosa-tiktok-ads/gmv_max'
import { removeUnicode } from '@/plugins/prototypes/function'

export default {
    name: 'GmvMaxCreateCampaign',

    props: {
        ads_account: null,
        tiktok_shop: null,
        state: null,
        campaign: null
    },

    data() {
        return {
            product_list: [],
            selected_product_list: [],
            identity_list: [],
            selected_identity_id: null,
            shopping_ads_type: 'PRODUCT',
            promote_all_products: true,
            cannot_promote_all_products: false,
            roas_bid: 0.2,
            budget: null,
            allow_end_time: false,
            start_time: new Date(),
            end_time: moment().add(30, 'day').toDate(),
            campaign_name: '',
            show_dialog: {
                create_success: false,
                select_product: false
            },
            loading: {
                create_campaign: false,
                update_campaign: false,
                get_campaign: false
            },
            product_filter: {
                search_keyword: '',
                status: 'AVAILABLE'
            },
            campaign_detail: {}
        }
    },

    computed: {
        c__product_filter_list() {
            let result = this.product_list

            result = result.filter(i => !this.selected_product_list.some(j => j.item_group_id === i.item_group_id))

            if (this.product_filter.status === 'AVAILABLE') {
                result = result.filter(i => i.status === this.product_filter.status)
            }

            const words = removeUnicode(this.product_filter.search_keyword)
            return result.filter(i => removeUnicode(JSON.stringify(i)).includes(words.toLowerCase()))
        },

        c__is_selected_all_products() {
            return this.product_list.length === this.product_list.filter(i => i.selected).length
        },

        c__is_indeterminate_products() {
            return this.product_list.some(i => i.selected) && !this.c__is_selected_all_products
        },

        c__count_selected_products() {
            return this.product_list.filter(i => i.selected).length
        }
    },

    watch: {
        shopping_ads_type() {
            this.campaign_name = `${this.shopping_ads_type} GMV Max_Gross revenue_${this.tiktok_shop.store_name}_${moment().format('YYYYMMDDHHmmss')}`
        }
    },

    async mounted() {
        await Promise.all([
            this.getTiktokShopProducts(),
            this.callGmvMaxGetIdentities(),
            this.callGmvMaxGetRecommendedGmv(),
            this.callGmvMaxCheckAvailabilityTiktokShop()
        ])
        // await this.getTiktokShopProducts()
        // await this.callGmvMaxGetIdentities()
        // await this.callGmvMaxGetRecommendedGmv()
        // await this.callGmvMaxCheckAvailabilityTiktokShop()

        this.campaign_name = `${this.shopping_ads_type} GMV Max_Gross revenue_${this.tiktok_shop.store_name}_${moment().format('YYYYMMDDHHmmss')}`

        if (this.campaign) {
            await this.callGetGmvMaxCampaignDetail()
            console.log(this.campaign_detail)
            this.shopping_ads_type = this.campaign_detail.shopping_ads_type
            if (this.shopping_ads_type === 'LIVE') {
                this.selected_identity_id = this.campaign_detail.identity_list[0].identity_id
            }
            this.selected_product_list = this.product_list.filter(i => this.campaign_detail.item_group_ids.includes(i.item_group_id))
            if (this.selected_product_list.length > 0) {
                this.promote_all_products = false
            }
            this.roas_bid = this.campaign_detail.roas_bid
            this.budget = this.campaign_detail.budget
            this.campaign_name = this.campaign_detail.campaign_name
            this.start_time = moment(this.campaign_detail.schedule_start_time, 'YYYY-MM-DD HH:mm:ss').toDate()
            this.end_time = moment(this.campaign_detail.schedule_end_time, 'YYYY-MM-DD HH:mm:ss').toDate()
            this.allow_end_time = this.campaign_detail.schedule_type === 'SCHEDULE_START_END'
        }
    },

    methods: {
        async callGetGmvMaxCampaignDetail() {
            this.loading.get_campaign = true

            const tiktok_account_id = this.$store.getters.tiktokBusiness.s__selected_tiktok_business.id
            const params = {
                advertiser_id: this.ads_account.advertiser_id,
                campaign_id: this.campaign.campaign_id
            }

            const response = await gmvMaxGetCampaignDetail(tiktok_account_id, params)
            // console.log(response)
            this.campaign_detail = response.data.data

            this.loading.get_campaign = false
        },

        onSelectedAllProducts(e) {
            console.log(e)
            if (e) {
                this.product_list.forEach(i => {
                    i.selected = true
                })
            } else {
                this.product_list.forEach(i => {
                    i.selected = false
                })
            }
        },

        showSelectProductsDialog() {
            for (const product of this.product_list) {
                product.selected = false
            }
            this.show_dialog.select_product = true
        },

        confirmSelectProducts() {
            this.selected_product_list = this.product_list.filter(i => i.selected)
            this.show_dialog.select_product = false
        },

        async callGmvMaxGetIdentities() {
            const tiktok_account_id = this.$store.getters.tiktokBusiness.s__selected_tiktok_business.id
            const params = {
                advertiser_id: this.ads_account.advertiser_id,
                store_id: this.tiktok_shop.store_id,
                store_authorized_bc_id: this.tiktok_shop.store_authorized_bc_id
            }

            const response = await gmvMaxGetIdentities(tiktok_account_id, params)
            // console.log(response)
            this.identity_list = response.data.data.identity_list
            if (this.identity_list.length > 0) {
                this.selected_identity_id = this.identity_list[0].identity_id
            }
        },

        async callGmvMaxGetRecommendedGmv() {
            const tiktok_account_id = this.$store.getters.tiktokBusiness.s__selected_tiktok_business.id
            const params = {
                advertiser_id: this.ads_account.advertiser_id,
                store_id: this.tiktok_shop.store_id,
                shopping_ads_type: this.shopping_ads_type,
                optimization_goal: 'VALUE'
            }

            const response = await gmvMaxGetRecommendedGmv(tiktok_account_id, params)
            // console.log(response)
            if (response && response.data && response.data.data) {
                this.roas_bid = response.data.data.roas_bid
                this.budget = response.data.data.budget
            }
        },

        async callGmvMaxCheckAvailabilityTiktokShop() {
            const tiktok_account_id = this.$store.getters.tiktokBusiness.s__selected_tiktok_business.id
            const params = {
                advertiser_id: this.ads_account.advertiser_id,
                store_id: this.tiktok_shop.store_id
            }

            const response = await gmvMaxCheckAvailabilityTiktokShop(tiktok_account_id, params)
            // console.log(response)
            this.cannot_promote_all_products = !response.data.data.promote_all_products_allowed
            if (this.cannot_promote_all_products) {
                this.promote_all_products = false
            }
        },

        async callGmvMaxCreateCampaign() {
            this.loading.create_campaign = true

            try {
                const tiktok_account_id = this.$store.getters.tiktokBusiness.s__selected_tiktok_business.id
                const body = {
                    'request_id': moment().unix().toString(),
                    'advertiser_id': this.ads_account.advertiser_id,
                    'store_id': this.tiktok_shop.store_id,
                    'store_authorized_bc_id': this.tiktok_shop.store_authorized_bc_id,
                    'shopping_ads_type': this.shopping_ads_type,
                    'product_specific_type': this.promote_all_products ? 'ALL' : 'CUSTOMIZED_PRODUCTS',
                    'item_group_ids': [],
                    'optimization_goal': 'VALUE',
                    'deep_bid_type': 'VO_MIN_ROAS',
                    'roas_bid': +this.roas_bid,
                    'budget': +this.budget,
                    'schedule_type': this.allow_end_time ? 'SCHEDULE_START_END' : 'SCHEDULE_FROM_NOW',
                    'schedule_start_time': moment(this.start_time).format('YYYY-MM-DD HH:mm:ss'),
                    'schedule_end_time': this.allow_end_time ? moment(this.end_time).format('YYYY-MM-DD HH:mm:ss') : null,
                    'product_video_specific_type': 'AUTO_SELECTION',
                    'identity_list': [],
                    'campaign_name': this.campaign_name
                }

                if (body.shopping_ads_type === 'PRODUCT' && body.product_specific_type === 'ALL') {
                    delete body.item_group_ids
                }
                if (body.shopping_ads_type === 'PRODUCT' && body.product_specific_type === 'CUSTOMIZED_PRODUCTS') {
                    body.item_group_ids = this.selected_product_list.map(i => i.item_group_id)
                    body.item_group_ids = body.item_group_ids.slice(0, 50)
                }
                if (body.shopping_ads_type === 'LIVE') {
                    delete body.item_group_ids
                }
                if (body.schedule_type === 'SCHEDULE_FROM_NOW') {
                    delete body.schedule_end_time
                }
                body.identity_list = this.identity_list.map(i => _pick(i, ['identity_id', 'identity_type', 'identity_authorized_bc_id', 'identity_authorized_shop_id', 'store_id']))

                const response = await gmvMaxCreateCampaign(tiktok_account_id, body)
                const {
                    code,
                    message
                } = response.data
                if (code === 0) {
                    // this.$notify({
                    //     title: 'Thông báo',
                    //     message: 'Tạo chiến dịch thành công',
                    //     type: 'success'
                    // })

                    this.show_dialog.create_success = true
                } else {
                    this.$notify({
                        title: 'Lỗi',
                        message: 'Đã có lỗi xảy ra khi tạo chiến dịch. Mã lỗi: ' + message,
                        type: 'warning'
                    })
                }
            } catch (e) {
                console.error(e)
            } finally {
                this.loading.create_campaign = false
            }
        },

        async getTiktokShopProducts() {
            const tiktok_account_id = this.$store.getters.tiktokBusiness.s__selected_tiktok_business.id
            const params = {
                bc_id: this.tiktok_shop.store_authorized_bc_id,
                store_id: this.tiktok_shop.store_id,
                page: 1,
                page_size: 100,
                ad_creation_eligible: 'GMV_MAX'
            }
            const product_list = []
            let response = await gmvMaxGetTiktokShopProducts(tiktok_account_id, params)
            // console.log(response)

            while (response && response.data && response.data.data && response.data.data.store_products && response.data.data.store_products.length > 0) {
                product_list.push(...response.data.data.store_products)

                params.page++
                response = await gmvMaxGetTiktokShopProducts(tiktok_account_id, params)
            }

            this.product_list = product_list.map(i => ({
                ...i,
                selected: false
            }))
            console.log(this.product_list)
        }
    }
}
</script>
