<template>
    <section class="py-[10px] px-[20px]">
        <!--        <pre>{{ selected_tiktok_shop }}</pre>-->

        <div v-if="current_component === 'list'">
            <div class="!bg-transparent p-[5px] mt-[10px]">
                <div class="flex justify-between items-center">
                    <p class="font-semibold text-[22px]">
                        {{ $t('gmv_max.title') }}
                    </p>

                    <div class="flex gap-[6px] cursor-pointer items-center" @click="show_dialog.select_shop = true">
                        <i class="el-icon-sort rotate-90"></i>
                        <span class="text-[14px]">
                        {{ $t('gmv_max.switch_another_shop') }}
                    </span>
                    </div>
                </div>
            </div>

            <div class="component-box mt-[20px]">
                <div class="flex justify-between items-center">
                    <div v-if="selected_tiktok_shop">
                        <div class="flex gap-[15px] items-center">
                            <div class="w-[40px] h-[40px]">
                                <img
                                    :src="selected_tiktok_shop.thumbnail_url"
                                    alt=""
                                    class="w-[100%] h-[100%]"
                                >
                            </div>
                            <div>
                                <div>
                                    <strong class="text-[16px]">
                                        {{ selected_tiktok_shop.store_name }}
                                    </strong>
                                </div>
                                <div>
                                <span class="text-[12px] text-[#888]">
                                    {{ $t('gmv_max.shop_id') }}: {{ selected_tiktok_shop.store_code }}
                                </span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <template v-if="selected_tiktok_shop">
                        <template v-if="selected_tiktok_shop.is_gmv_max_available">
                            <el-button
                                size="medium"
                                type="primary"
                                icon="el-icon-plus"
                                @click="current_component = 'create'; selected_campaign = null"
                            >
                                {{ $t('gmv_max.create_ad') }}
                            </el-button>
                        </template>
                        <template v-else>
                            <el-tooltip
                                class="item"
                                effect="dark"
                                placement="top"
                            >
                                <el-button
                                    size="medium"
                                    type="primary"
                                    icon="el-icon-plus"
                                    :disabled="true"
                                >
                                    {{ $t('gmv_max.create_ad') }}
                                </el-button>

                                <div slot="content">
                                    <div class="max-w-[300px]">
                                        {{ $t('gmv_max.not_authorized_to_create_gmv_max') }}
                                    </div>
                                </div>
                            </el-tooltip>
                        </template>
                    </template>
                </div>
            </div>

            <div class="component-box mt-[30px]">
                <div>
                    <div class="flex justify-between items-center">
                        <div>
                            <div v-loading="fetching_ads_accounts" class="flex flex-wrap gap-2">
                                <label-outside-input-select
                                    :label="$t('common.advertiser_account')"
                                >
                                    <el-select
                                        :value="selected_ads_account"
                                        value-key="advertiser_id"
                                        size="small"
                                        filterable
                                        :placeholder="
                            $t('input.placeholder.select_ads_account')
                        "
                                        class="w-[260px]"
                                        @change="handleChangeAdsAcc"
                                    >
                                        <el-option-group
                                            :label="$t('common.advertiser_account')"
                                        >
                                            <el-option
                                                v-for="item in m__ads_accounts"
                                                :key="item.advertiser_id"
                                                :label="item.name"
                                                :value="item"
                                            >
                                                <div class="relative">
                                                    <div
                                                        v-if="item.belong_to_atosa"
                                                        class="-right-4 bg-brand-atosa--light util-flex-center absolute bottom-0 h-5 px-1 text-white"
                                                    >
                                        <span class="text-xxxs uppercase">
                                            atosa
                                        </span>
                                                    </div>
                                                    <div class="flex flex-col py-1">
                                        <span
                                            class="text-xs font-semibold leading-5"
                                        >
                                            {{ item.name }}
                                        </span>
                                                        <div
                                                            class="flex items-center space-x-1 leading-3"
                                                        >
                                                            <i class="el-icon-money"></i>

                                                            <span class="text-xs">
                                                {{
                                                                    p__renderMoneyByCurrency(
                                                                        item.balance,
                                                                        item.currency
                                                                    )
                                                                }}
                                            </span>
                                                        </div>
                                                        <span class="component-text-desc">
                                            #{{ item.advertiser_id }}
                                        </span>
                                                    </div>
                                                </div>
                                            </el-option>
                                        </el-option-group>
                                    </el-select>
                                </label-outside-input-select>

                                <label-outside-input-select :label="$t('common.balance')">
                                    <el-input
                                        size="small"
                                        :value="`${p__formatCurrency(
                            current_balance
                        )} ${currency}`"
                                        class="w-[140px]"
                                    ></el-input>
                                </label-outside-input-select>

                                <div v-if="false">
                                    <el-tooltip
                                        class="item"
                                        effect="light"
                                        placement="top"
                                    >
                                        <el-button
                                            size="small"
                                            icon="el-icon-edit"
                                            @click="show_dialog.update_cookie = true"
                                        >
                                            {{ $t('button.update_cookie') }}
                                        </el-button>
                                        <div slot="content">
                                            <div class="text-[12px]">
                                                {{ $t('common.status') }}:
                                                <template v-if="selected_ads_account && selected_ads_account.cookie">
                                                    {{ $t('common.active') }}
                                                </template>
                                                <template v-else>
                                                    {{ $t('common.inactive') }}
                                                </template>
                                            </div>
                                            <div class="max-w-[300px] mt-[6px] text-[12px]">
                                                {{ $t('gmv_max.cookie_note') }}
                                            </div>
                                        </div>
                                    </el-tooltip>
                                </div>
                            </div>
                        </div>
                        <div class="flex gap-[10px]">
                            <el-button
                                size="small"
                                icon="el-icon-refresh"
                                :disabled="loading.get_ads_data"
                                @click="getOverviewStats(); callGetCampaigns()"
                            >
                                {{ $t('button.reload') }}
                            </el-button>
                            <el-date-picker
                                v-model="m__ranger_date"
                                class="!w-[360px]"
                                size="small"
                                type="daterange"
                                unlink-panels
                                popper-class="custom-el-date"
                                :start-placeholder="$t('date_picker.placeholder.start')"
                                :end-placeholder="$t('date_picker.placeholder.end')"
                                :picker-options="m__picker_options"
                                :format="$t('common.format_value_date_picker')"
                                value-format="yyyy-MM-dd"
                                @change="getOverviewStats(); callGetCampaigns()"
                            >
                            </el-date-picker>
                        </div>
                    </div>

                    <div class="mt-[40px]">
                        <GmvMaxMetrics
                            :currency="currency"
                            :overview_stats="overview_stats"
                            :overview_charts="overview_charts"
                        ></GmvMaxMetrics>
                    </div>

                    <div class="mt-[40px]">
                        <div>
                            <div>
                                <strong class="text-[16px]">{{ $t('gmv_max.campaign_list') }}</strong>
                            </div>
                            <div>
                        <span class="text-[#0000008C] text-[12px]">
                            {{ $t('gmv_max.campaign_list_note') }}
                        </span>
                            </div>
                        </div>

                        <div class="mt-[20px]">
                            <el-tabs
                                v-model="campaign_list_tab"
                                class="custom-el-tabs"
                                :disabled="loading.get_ads_data"
                            >
                                <el-tab-pane label="Product GMV Max" name="PRODUCT_GMV_MAX">
                                    <!--                                Product GMV Max-->
                                </el-tab-pane>
                                <el-tab-pane label="Live GMV Max" name="LIVE_GMV_MAX">
                                    <!--                                Live GMV Max-->
                                </el-tab-pane>
                            </el-tabs>

                            <div class="mt-[15px] flex justify-between">
                                <div class="flex gap-[10px]">
                                    <div class="w-[254px]">
                                        <el-input
                                            v-model="campaign_filter.search_keyword"
                                            size="small"
                                            prefix-icon="el-icon-search"
                                            :placeholder="$t('input.placeholder.campaign_content')"
                                            class="w-full"
                                        ></el-input>
                                    </div>
                                    <div class="w-[174px]">
                                        <el-select
                                            v-model="campaign_filter.status"
                                            value=""
                                            size="small"
                                            :placeholder="$t('common.status')"
                                            class="w-full"
                                            clearable
                                        >
                                            <el-option value="ENABLE" :label="$t('common.active')"></el-option>
                                            <el-option value="DISABLE" :label="$t('common.inactive')"></el-option>
                                        </el-select>
                                    </div>
                                </div>
                                <div>
                                    <el-button
                                        size="small"
                                        @click="showCompareDataDialog()"
                                    >
                                        {{ $t('button.compare_data') }}

                                        <strong
                                            v-if="is_compare_data"
                                            style="font-weight: 700"
                                            class="text-success"
                                        >
                                            [ ON ]
                                        </strong>
                                        <strong v-else style="font-weight: 700" class="text-danger">
                                            [ OFF ]
                                        </strong>
                                    </el-button>
                                    <el-button
                                        size="small"
                                        @click="exportCampaignsToCSV()"
                                    >
                                        {{ $t('button.export_data') }}
                                    </el-button>
                                </div>
                            </div>

                            <div class="mt-[15px]">
                                <el-table
                                    ref="campaign_table"
                                    v-loading="loading.get_ads_data"
                                    :data="c__campaign_filter_list"
                                    class="gmv-max-table"
                                >
                                    <el-table-column
                                        :label="$t('table.header.on_off')"
                                        width="80px"
                                        fixed="left"
                                    >
                                        <template slot-scope="scope">
                                            <el-switch
                                                v-model="scope.row.operation_status"
                                                active-value="ENABLE"
                                                inactive-value="DISABLE"
                                                @change="updateCampaignOperationStatus(scope.row, $event)"
                                            ></el-switch>
                                        </template>
                                    </el-table-column>
                                    <el-table-column
                                        :label="$t('page.campaign.campaign_name')"
                                        width="305px"
                                        fixed="left"
                                        show-tooltip-when-overflow
                                        prop="campaign_name"
                                    >
                                        <template slot-scope="scope">
                                            <div class="cursor-pointer" @click="showCampaignDetail(scope.row)">
                                                {{ scope.row.campaign_name }}
                                            </div>

                                            <div class="mt-[5px] h-[15px] flex gap-[10px]">
                                                <a
                                                    href="javascript:void(0)"
                                                    class="text-primary"
                                                    @click="showUpdateCampaignComponent(scope.row)"
                                                >
                                                    {{ $t('button.edit_campaign') }}
                                                </a>
                                                <a
                                                    href="javascript:void(0)"
                                                    class="text-primary"
                                                    @click="confirmDeleteCampaign(scope.row)"
                                                >
                                                    {{ $t('button.delete') }}
                                                </a>
                                            </div>
                                        </template>
                                    </el-table-column>
                                    <el-table-column
                                        :label="$t('common.status')"
                                        width="150px"
                                    >
                                        <template slot-scope="scope">
                                            <!--                                            {{ scope.row.secondary_status }}-->
                                            <div
                                                v-if="scope.row.secondary_status === 'CAMPAIGN_STATUS_ENABLE'"
                                                class="text-gray flex items-center"
                                            >
                                                <div class="active-circle"></div>
                                                {{ $t('common.active') }}
                                            </div>
                                            <div
                                                v-else-if="scope.row.secondary_status === 'CAMPAIGN_STATUS_DISABLE'"
                                                class="text-gray flex items-center"
                                            >
                                                <div class="inactive-circle"></div>
                                                {{ $t('common.inactive') }}
                                            </div>
                                            <div
                                                v-else-if="scope.row.secondary_status === 'CAMPAIGN_STATUS_PRODUCT_UNAVAILABLE'"
                                                class="text-gray flex items-center"
                                            >
                                                <div class="inactive-circle"></div>
                                                {{ $t('common.not_delivering') }}
                                            </div>
                                            <div
                                                v-else-if="scope.row.secondary_status === 'CAMPAIGN_STATUS_PRODUCT_USED_BY_PRODUCT_GMV_MAX'"
                                                class="text-gray flex items-center"
                                            >
                                                <div class="inactive-circle"></div>
                                                {{ $t('common.inactive') }}
                                            </div>
                                        </template>
                                    </el-table-column>
                                    <el-table-column
                                        :label="$t('page.campaign.schedule_time')"
                                        width="150px"
                                    >
                                        <template slot-scope="scope">
                                            {{ scope.row.modify_time }}
                                        </template>
                                    </el-table-column>
                                    <el-table-column
                                        :label="$t('gmv_max.target_roi_budget')"
                                        width="150px"
                                    >
                                        <template slot-scope="scope">
                                            {{ p__formatCurrency(scope.row.target_roi_budget) }}
                                        </template>
                                    </el-table-column>
                                    <el-table-column
                                        :label="$t('gmv_max.max_delivery_budget')"
                                        width="130px"
                                        prop="max_delivery_budget"
                                    >
                                        <template slot-scope="scope">
                                            {{ p__formatCurrency(scope.row.max_delivery_budget) }}
                                        </template>
                                    </el-table-column>
                                    <el-table-column
                                        :label="$t('gmv_max.target_roi')"
                                        width="130px"
                                        prop="roas_bid"
                                    >
                                        <template slot-scope="scope">
                                            {{ parseFloat(scope.row.roas_bid).toFixed(2) }}
                                        </template>
                                    </el-table-column>
                                    <el-table-column
                                        :label="$t('gmv_max.cost')"
                                        width="130px"
                                        prop="cost"
                                        sortable
                                    >
                                        <template slot-scope="scope">
                                            {{ p__formatCurrency(scope.row.cost) }}
                                        </template>
                                    </el-table-column>
                                    <el-table-column
                                        :label="$t('gmv_max.net_cost')"
                                        width="130px"
                                        prop="net_cost"
                                        sortable
                                    >
                                        <template slot-scope="scope">
                                            {{ p__formatCurrency(scope.row.net_cost) }}
                                        </template>
                                    </el-table-column>
                                    <el-table-column
                                        :label="$t('gmv_max.orders') + ' (SKU)'"
                                        width="130px"
                                        prop="orders"
                                        sortable
                                    >
                                        <template slot-scope="scope">
                                            {{ p__formatCurrency(scope.row.orders) }}
                                        </template>
                                    </el-table-column>
                                    <el-table-column
                                        :label="$t('gmv_max.cost_per_order')"
                                        width="180px"
                                        prop="cost_per_order"
                                        sortable
                                    >
                                        <template slot-scope="scope">
                                            {{ p__formatCurrency(scope.row.cost_per_order) }}
                                        </template>
                                    </el-table-column>
                                    <el-table-column
                                        :label="$t('gmv_max.gross_revenue')"
                                        width="150px"
                                        prop="gross_revenue"
                                        sortable
                                    >
                                        <template slot-scope="scope">
                                            {{ p__formatCurrency(scope.row.gross_revenue) }}
                                        </template>
                                    </el-table-column>
                                    <el-table-column
                                        :label="`ROI`"
                                        width="150px"
                                        prop="roi"
                                        sortable
                                    >
                                        <template slot-scope="scope">
                                            {{ parseFloat(scope.row.roi).toFixed(2) }}
                                        </template>
                                    </el-table-column>
                                    <template v-if="campaign_list_tab === 'LIVE_GMV_MAX'">
                                        <el-table-column
                                            :label="`LIVE views`"
                                            width="150px"
                                            prop="live_views"
                                            sortable
                                        >
                                            <template slot-scope="scope">
                                                {{ p__formatCurrency(scope.row.live_views) }}
                                            </template>
                                        </el-table-column>
                                        <el-table-column
                                            :label="`Cost per LIVE view`"
                                            width="150px"
                                            prop="cost_per_live_view"
                                            sortable
                                        >
                                            <template slot-scope="scope">
                                                {{ p__formatCurrency(scope.row.cost_per_live_view) }}
                                            </template>
                                        </el-table-column>
                                        <el-table-column
                                            :label="`10-second LIVE views`"
                                            width="150px"
                                            prop="10_second_live_views"
                                            sortable
                                        >
                                            <template slot-scope="scope">
                                                {{ p__formatCurrency(scope.row['10_second_live_views']) }}
                                            </template>
                                        </el-table-column>
                                        <el-table-column
                                            :label="`Cost per 10-second LIVE views`"
                                            width="150px"
                                            prop="cost_per_10_second_live_view"
                                            sortable
                                        >
                                            <template slot-scope="scope">
                                                {{ p__formatCurrency(scope.row.cost_per_10_second_live_view)
                                                }}
                                            </template>
                                        </el-table-column>
                                        <el-table-column
                                            :label="`LIVE follows`"
                                            width="150px"
                                            prop="live_follows"
                                            sortable
                                        >
                                            <template slot-scope="scope">
                                                {{ p__formatCurrency(scope.row.live_follows) }}
                                            </template>
                                        </el-table-column>
                                    </template>
                                    <!--                                    <el-table-column
                                                                            :label="$t('common.optional')"
                                                                            width="120px"
                                                                        >
                                                                            <template slot-scope="scope">
                                                                                <el-button type="text" @click="showUpdateCampaignComponent(scope.row)">
                                                                                    <strong class="text-primary">{{ $t('button.edit') }}</strong>
                                                                                </el-button>
                                                                                <el-button type="text" @click="confirmDeleteCampaign(scope.row)">
                                                                                    <strong class="text-danger">{{ $t('button.delete') }}</strong>
                                                                                </el-button>
                                                                            </template>
                                                                        </el-table-column>-->
                                </el-table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div v-if="current_component === 'create'">
            <GmvMaxCreateCampaign
                :ads_account="selected_ads_account"
                :tiktok_shop="selected_tiktok_shop"
                :campaign="selected_campaign"
                @back_to_list="backToListComponent"
            ></GmvMaxCreateCampaign>
        </div>

        <el-dialog
            width="600px"
            :close-on-click-modal="false"
            :visible.sync="show_dialog.select_shop"
            :show-close="false"
            custom-class="no-body"
            @close="cancelSelectShop"
        >
            <div slot="title">
                <div>
                    <strong class="text-[18px]">
                        {{ $t('gmv_max.select_a_shop') }}
                    </strong>
                </div>
            </div>
            <div slot="default" v-loading="loading.get_shops">
                <div class="mt-[15px]">
                    <div>
                        <div v-loading="fetching_ads_accounts" class="flex flex-wrap gap-2">
                            <label-outside-input-select
                                :label="$t('common.advertiser_account')"
                            >
                                <el-select
                                    v-model="selected_ads_account_for_shop"
                                    value-key="advertiser_id"
                                    size="small"
                                    filterable
                                    :placeholder="$t('input.placeholder.select_ads_account')"
                                    class="w-[260px]"
                                >
                                    <el-option-group
                                        :label="$t('common.advertiser_account')"
                                    >
                                        <el-option
                                            v-for="item in m__ads_accounts"
                                            :key="item.advertiser_id"
                                            :label="item.name"
                                            :value="item"
                                        >
                                            <div class="relative">
                                                <div
                                                    v-if="item.belong_to_atosa"
                                                    class="-right-4 bg-brand-atosa--light util-flex-center absolute bottom-0 h-5 px-1 text-white"
                                                >
                                        <span class="text-xxxs uppercase">
                                            atosa
                                        </span>
                                                </div>
                                                <div class="flex flex-col py-1">
                                        <span
                                            class="text-xs font-semibold leading-5"
                                        >
                                            {{ item.name }}
                                        </span>
                                                    <div
                                                        class="flex items-center space-x-1 leading-3"
                                                    >
                                                        <i class="el-icon-money"></i>

                                                        <span class="text-xs">
                                                {{
                                                                p__renderMoneyByCurrency(
                                                                    item.balance,
                                                                    item.currency
                                                                )
                                                            }}
                                            </span>
                                                    </div>
                                                    <span class="component-text-desc">
                                            #{{ item.advertiser_id }}
                                        </span>
                                                </div>
                                            </div>
                                        </el-option>
                                    </el-option-group>
                                </el-select>
                            </label-outside-input-select>

                            <label-outside-input-select :label="$t('common.balance')">
                                <el-input
                                    size="small"
                                    :value="`${p__formatCurrency(
                            current_balance
                        )} ${currency}`"
                                    class="w-[140px]"
                                ></el-input>
                            </label-outside-input-select>
                        </div>
                    </div>
                </div>

                <div class="mt-[30px]">
                    <strong class="text-black text-[14px]">
                        {{ $t('gmv_max.available_shops') }}
                        ({{ tiktok_shop_list.length }})
                    </strong>
                </div>

                <div class="mt-[15px]">
                    <div class="flex flex-col gap-[25px]">
                        <div
                            v-for="shop of tiktok_shop_list"
                            :key="shop.store_code"
                            class="flex gap-[10px] items-center cursor-pointer"
                            @click="selected_tiktok_shop_id = shop.store_code"
                        >
                            <div class="text-center">
                                <el-radio
                                    v-model="selected_tiktok_shop_id"
                                    :label="shop.store_code"
                                    class="hide-el-radio-label"
                                ></el-radio>
                            </div>
                            <div class="w-[40px] h-[40px]">
                                <img
                                    :src="shop.thumbnail_url"
                                    alt=""
                                    class="w-[100%] h-[100%] object-contain"
                                >
                            </div>
                            <div>
                                <div>
                                    <strong class="text-[16px]">
                                        {{ shop.store_name }}
                                    </strong>
                                </div>
                                <div style="margin-top: 2px">
                                    <div class="text-[12px] text-[#888]">
                                        <template v-if="!shop.is_gmv_max_available">
                                            {{ $t('gmv_max.need_to_authorized') }} ·
                                        </template>
                                        {{ $t('gmv_max.shop_id') }}: {{ shop.store_code }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="mt-[40px] text-right">
                    <el-button size="small" @click="show_dialog.select_shop = false">{{ $t('button.cancel') }}
                    </el-button>
                    <el-button type="primary" size="small" @click="confirmSelectShop()">{{ $t('button.continue') }}
                    </el-button>
                </div>
            </div>
        </el-dialog>

        <el-drawer
            :visible.sync="show_dialog.campaign_detail"
            size="65%"
            :with-header="false"
        >
            <GmvMaxCampaignDetail
                :ads_account="selected_ads_account"
                :tiktok_shop="selected_tiktok_shop"
                :campaign="selected_campaign"
            ></GmvMaxCampaignDetail>
        </el-drawer>

        <el-dialog
            width="600px"
            :close-on-click-modal="false"
            :visible.sync="show_dialog.update_cookie"
            custom-class="no-body"
            @close="cancelSelectShop"
        >
            <div slot="title">
                <div>
                    <strong class="text-[20px]">
                        {{ $t('gmv_max.update_cookie_for_ad_account') }}
                    </strong>
                </div>
            </div>
            <div>
                <div class="text-[14px]">
                    <div class="mt-[5px] flex gap-[5px]">
                        - {{ $t('common.status') }}:
                        <div v-if="selected_ads_account && selected_ads_account.cookie" class="text-success">
                            {{ $t('common.active') }}
                        </div>
                        <div v-else class="text-danger">
                            {{ $t('common.inactive') }}
                        </div>
                    </div>
                    <div class="mt-[5px]">
                        - {{ $t('gmv_max.update_cookie_guide') }}
                        <a href="" target="_blank" class="text-primary underline">
                            {{ $t('gmv_max.update_cookie_link') }}
                        </a>
                    </div>
                    <div class="mt-[5px]">
                        - {{ $t('gmv_max.not_share_cookie') }}
                    </div>
                </div>
                <div class="mt-[40px] text-center">
                    <el-button
                        type="primary"
                        size="small"
                        icon="el-icon-upload"
                        :loading="loading.update_cookie"
                        @click="importCookieFile()"
                    >
                        {{ $t('gmv_max.upload_cookie_file') }}
                    </el-button>
                </div>
            </div>
        </el-dialog>

        <el-dialog
            width="600px"
            :close-on-click-modal="false"
            :visible.sync="show_dialog.compare_data"
            custom-class="no-body"
        >
            <div slot="title">
                <div>
                    <strong class="text-[20px]">
                        {{ $t('button.compare_data') }}
                    </strong>
                </div>
            </div>
        </el-dialog>

        <input id="input_control_detail" type="file" style="display: none;" @change="handleCookie">
    </section>
</template>

<script>
import selectedTiktokBusinessMixin from '@/plugins/mixins/selected-tiktok-business'
import rangerDate from '@/plugins/mixins/ranger-date'
import { gmvMaxGetCampaigns, gmvMaxGetReport, gmvMaxGetTiktokShop } from '@/services/atosa-tiktok-ads/gmv_max'
import { updateCampaignStatus } from '@/services/atosa-tiktok-ads/campaign'
import GmvMaxCreateCampaign from './component/create.vue'
import GmvMaxCampaignDetail from './component/detail'
import GmvMaxMetrics from './component/metrics'
import moment from 'moment'
import { submitGetRequest, submitPostRequest } from '@/services/atosa-tiktok-ads/tool'
import { updateAdvertiserAccountCookie } from '@/services/atosa-tiktok-ads/advertiser-account'
import { removeUnicode } from '@/plugins/prototypes/function'

export default {
    name: 'GmvMax',

    components: {
        GmvMaxCreateCampaign,
        GmvMaxCampaignDetail,
        GmvMaxMetrics
    },

    mixins: [selectedTiktokBusinessMixin, rangerDate],

    data() {
        return {
            current_component: 'list',
            selected_ads_account: null,
            selected_ads_account_for_shop: null,
            current_balance: 0,
            currency: 'VND',
            fetching_ads_accounts: false,

            show_dialog: {
                select_shop: true,
                campaign_detail: false,
                update_cookie: false,
                compare_data: false
            },
            loading: {
                get_shops: false,
                get_ads_data: false,
                update_cookie: false,
                compare_data: false
            },

            tiktok_shop_list: [],
            selected_tiktok_shop: null,
            selected_tiktok_shop_id: null,
            campaign_list_tab: 'PRODUCT_GMV_MAX',
            campaign_list: [],

            selected_campaign: null,

            campaign_filter: {
                search_keyword: '',
                status: ''
            },

            overview_stats: {},
            overview_charts: [],

            is_compare_data: false
        }
    },

    computed: {
        c__request_headers() {
            return {
                'accept': 'application/json, text/plain, */*',
                'accept-language': 'en,vi;q=0.9,en-US;q=0.8,vi-VN;q=0.7',
                'content-type': 'application/json; charset=UTF-8',
                'origin': 'https://ads.tiktok.com',
                'priority': 'u=1, i',
                'referer': 'https://ads.tiktok.com/i18n/gmv-max/dashboard',
                'sec-ch-ua': '"Not A(Brand";v="8", "Chromium";v="132", "Google Chrome";v="132"',
                'sec-ch-ua-mobile': '?0',
                'sec-ch-ua-platform': '"macOS"',
                'sec-fetch-dest': 'empty',
                'sec-fetch-mode': 'cors',
                'sec-fetch-site': 'same-origin',
                'user-agent': navigator.userAgent
            }
        },

        c__campaign_filter_list() {
            let result = this.campaign_list

            if (this.campaign_filter.status) {
                result = result.filter(i => i.operation_status === this.campaign_filter.status)
            }

            const words = removeUnicode(this.campaign_filter.search_keyword)
            return result.filter(i => removeUnicode(JSON.stringify(i)).includes(words.toLowerCase()))
        }
    },

    watch: {
        m__selected_tiktok_business() {
            this.m__fetchAdsAccountsByTiktokBusiness()
                .then(() => {
                    if (this.m__ads_accounts.length) this.selected_ads_account_for_shop = this.m__ads_accounts[0]
                    // if (this.m__ads_accounts.length) this.selected_ads_account = this.m__ads_accounts[0]

                    // todo: test
                    const find = this.m__ads_accounts.find(i => i.advertiser_id.toString() === '7405538062727692305')
                    if (find) this.selected_ads_account_for_shop = find
                    // if (find) this.selected_ads_account = find
                })
        },

        selected_ads_account() {
            this.updateCookie(this.selected_ads_account.cookie)
            this.getOverviewStats()
            this.callGetCampaigns()
        },

        selected_ads_account_for_shop() {
            this.current_balance = this.selected_ads_account_for_shop.balance
            this.currency = this.selected_ads_account_for_shop.currency
            this.callGmvMaxGetTiktokShop()
        },

        campaign_list_tab() {
            this.callGetCampaigns()
        }
    },

    async mounted() {
        this.loading.get_shops = true
        this.tiktok_account_id = this.$store.getters.tiktokBusiness.s__selected_tiktok_business.id
        await this.m__fetchAdsAccountsByTiktokBusiness()
        if (this.m__ads_accounts.length) this.selected_ads_account_for_shop = this.m__ads_accounts[0]
        // if (this.m__ads_accounts.length) this.selected_ads_account = this.m__ads_accounts[0]
        this.loading.get_shops = false

        // todo: test
        const find = this.m__ads_accounts.find(i => i.advertiser_id.toString() === '7405538062727692305')
        if (find) this.selected_ads_account_for_shop = find
        // if (find) this.selected_ads_account = find
    },

    methods: {
        showCompareDataDialog() {
            this.show_dialog.compare_data = true
        },

        exportCampaignsToCSV() {
            console.log(this.$refs.campaign_table)

            const headers = this.$refs.campaign_table.columns.filter(i => i.property).map(i => i.label).join(',')
            const data = ['Campaign ID,' + headers]
            for (const campaign of this.campaign_list) {
                const row = [campaign.campaign_id]
                for (const column of this.$refs.campaign_table.columns) {
                    if (column.property) {
                        if (typeof campaign[column.property] === 'string' && !isNaN(campaign[column.property])) {
                            row.push(+campaign[column.property])
                        } else {
                            row.push(campaign[column.property])
                        }
                    }
                }
                data.push(row.join(','))
            }

            const csv = data.join('\n')
            const blob = new Blob([csv], { type: 'text/csv' })
            const url = window.URL.createObjectURL(blob)
            const a = document.createElement('a')
            a.href = url
            a.setAttribute('download', `${this.campaign_list_tab} data ${moment(this.m__ranger_date[0]).format('YYYY-MM-DD')} - ${moment(this.m__ranger_date[1]).format('YYYY-MM-DD')}.csv`)
            document.body.appendChild(a)
            a.click()
            document.body.removeChild(a)
        },

        importCookieFile() {
            this.$el.querySelector(`#input_control_detail`).click()
        },

        handleCookie(e) {
            try {
                const file_store = e.target.files || e.dataTransfer.files
                if (file_store.length === 0) {
                    return
                }

                const input = e.target
                const reader = new FileReader()
                reader.onload = async () => {
                    const text = reader.result
                    // console.log(text)

                    this.loading.update_cookie = true
                    const json = JSON.parse(text.toString())
                    const cookie = json.cookies.map(i => `${i.name}=${i.value}`).join('; ')
                    await this.updateCookie(cookie)
                    this.show_dialog.update_cookie = false
                    this.loading.update_cookie = false
                }
                reader.readAsText(input.files[0])
            } catch (e) {
                console.error(e)
            } finally {
                e.target.value = null
            }
        },

        async updateCookie(cookie) {
            if (!cookie) return

            try {
                const response = await submitGetRequest({
                    url: 'https://ads.tiktok.com/passport/web/account/info/',
                    params: {},
                    headers: {
                        ...this.c__request_headers,
                        'cookie': cookie
                    }
                })

                if (response && response.data && response.data.data && response.data.data.data) {
                    const data = response.data.data.data
                    if (data.user_id) {
                        this.$notify({
                            title: this.$t('common.notice'),
                            message: this.$t('gmv_max.add_cookie_success'),
                            type: 'success'
                        })

                        // console.log(response.data.headers)
                        // const merged_cookie = this.mergeCookie(co)

                        await updateAdvertiserAccountCookie({
                            advertiser_id: this.selected_ads_account_for_shop.advertiser_id,
                            cookie: cookie
                        })

                        this.selected_ads_account.cookie = cookie
                    } else {
                        this.$notify({
                            title: this.$t('common.notice'),
                            message: this.$t('gmv_max.add_cookie_error') + data.description,
                            type: 'warning'
                        })
                        this.selected_ads_account.cookie = null
                    }
                } else {
                    this.$notify({
                        title: this.$t('common.notice'),
                        message: this.$t('gmv_max.invalid_cookie'),
                        type: 'warning'
                    })
                    this.selected_ads_account.cookie = null
                }
            } catch (e) {
                this.$notify({
                    title: this.$t('common.notice'),
                    message: this.$t('gmv_max.invalid_cookie'),
                    type: 'warning'
                })
                this.selected_ads_account.cookie = null
            }
        },

        mergeCookie(old_cookie_string, new_cookie_string) {
            const old_cookie = old_cookie_string.split(';').map(i => ({
                name: i.split('=')[0].trim(),
                value: i.split('=')[1].trim()
            }))
            const new_cookie = new_cookie_string.split(';').map(i => ({
                name: i.split('=')[0].trim(),
                value: i.split('=')[1].trim()
            }))

            return [...old_cookie, ...new_cookie].reduce((acc, cur) => {
                const find = acc.find(i => i.name === cur.name)
                if (find) {
                    find.value = cur.value
                } else {
                    acc.push(cur)
                }
                return acc
            }, []).map(i => `${i.name}=${i.value}`).join('; ')
        },

        backToListComponent(reload_campaigns = false) {
            this.current_component = 'list'

            if (reload_campaigns) {
                this.callGetCampaigns()
            }
        },

        cancelSelectShop() {
            if (!this.selected_tiktok_shop) {
                this.$router.push('/ads-manager/campaign')
            }
        },

        handleChangeAdsAcc(value) {
            this.selected_ads_account = value
            this.current_balance = value.balance
            this.currency = value.currency
        },

        async callGmvMaxGetTiktokShop() {
            if (!this.selected_ads_account_for_shop) return

            this.loading.get_shops = true

            this.tiktok_shop_list = []
            // this.selected_tiktok_shop = null
            this.selected_tiktok_shop_id = null

            const tiktok_account_id = this.$store.getters.tiktokBusiness.s__selected_tiktok_business.id
            const params = { advertiser_id: this.selected_ads_account_for_shop.advertiser_id }
            const response = await gmvMaxGetTiktokShop(tiktok_account_id, params)
            if (response && response.data && response.data.data && response.data.data.store_list) {
                this.tiktok_shop_list = response.data.data.store_list
                // console.log(this.tiktok_shop_list)

                if (this.tiktok_shop_list.length) {
                    this.selected_tiktok_shop_id = this.tiktok_shop_list[0].store_code
                }
            }

            this.loading.get_shops = false
        },

        async confirmSelectShop() {
            if (!this.selected_tiktok_shop_id) return

            this.selected_ads_account = JSON.parse(JSON.stringify(this.selected_ads_account_for_shop))
            this.selected_tiktok_shop = this.tiktok_shop_list.find(i => i.store_code === this.selected_tiktok_shop_id)
            this.show_dialog.select_shop = false

            // await this.getOverviewStats()
            // await this.callGetCampaigns()
        },

        async getOverviewStats() {
            // this.overview_stats = null
            // const url = `https://ads.tiktok.com/api/oec_shopping/v1/oec/stat/post_overview_stat?locale=vi&language=vi&oec_seller_id=${this.selected_tiktok_shop.store_id}&aadvid=${this.selected_ads_account.advertiser_id}&bc_id=${this.selected_ads_account.bc_id}`
            // const data = {
            //     'query_list': [
            //         'cost',
            //         'onsite_roi2_shopping_sku',
            //         'cost_per_onsite_roi2_shopping_sku',
            //         'onsite_roi2_shopping_value',
            //         'onsite_roi2_shopping'
            //     ],
            //     'start_time': moment(this.m__ranger_date[0]).format('YYYY-MM-DD'),
            //     'end_time': moment(this.m__ranger_date[1]).format('YYYY-MM-DD'),
            //     'campaign_shop_automation_type': 2,
            //     'external_type_list': [
            //         '307',
            //         '304',
            //         '305'
            //     ]
            // }
            //
            // const response = await submitPostRequest({
            //     url,
            //     data,
            //     params: {},
            //     headers: {
            //         ...this.c__request_headers,
            //         'cookie': this.selected_ads_account.cookie
            //     }
            // })
            // // console.log('overview stats', response)
            // this.overview_stats = response.data.data.data

            this.overview_stats = {}
            this.overview_charts = []
            const tiktok_account_id = this.$store.getters.tiktokBusiness.s__selected_tiktok_business.id
            const params = {
                advertiser_id: this.selected_ads_account.advertiser_id,
                store_ids: `["${this.selected_tiktok_shop.store_id}"]`,
                start_date: moment(this.m__ranger_date[0]).format('YYYY-MM-DD'),
                end_date: moment(this.m__ranger_date[1]).format('YYYY-MM-DD'),
                dimensions: `["advertiser_id", "stat_time_day"]`,
                metrics: `["cost", "orders", "cost_per_order", "gross_revenue", "roi", "net_cost"]`,
                page: 1,
                page_size: 1000
            }
            if (params.start_date === params.end_date) {
                params.dimensions = `["advertiser_id", "stat_time_hour"]`
            }
            let response = await gmvMaxGetReport(tiktok_account_id, params)
            // console.log('report', response)

            const report_data = []
            while (response && response.data && response.data.data && response.data.data.list && response.data.data.list.length) {
                report_data.push(...response.data.data.list)

                if (report_data.length >= response.data.data.page_info.total_number) break

                params.page++
                response = await gmvMaxGetReport(tiktok_account_id, params)
            }

            for (const item of report_data) {
                for (const key in item.metrics) {
                    item.metrics[key] = Number(item.metrics[key])
                }

                this.overview_charts.push({
                    ...item.metrics,
                    unix: moment(item.dimensions.stat_time_day).unix(),
                    label: params.start_date === params.end_date
                        ? moment(item.dimensions.stat_time_day).format('HH:mm')
                        : moment(item.dimensions.stat_time_day).format('MM-DD')
                })

                for (const key of Object.keys(item.metrics)) {
                    if (!this.overview_stats[key]) this.overview_stats[key] = 0
                    this.overview_stats[key] += item.metrics[key]
                }
            }
            this.overview_stats.roi = this.overview_stats.cost
                ? this.overview_stats.gross_revenue / this.overview_stats.cost
                : 0
            this.overview_stats = JSON.parse(JSON.stringify(this.overview_stats))
            this.overview_charts = this.overview_charts.sort((a, b) => a.unix - b.unix)
        },

        async getCampaignStats() {
            // console.log(this.selected_ads_account)
            // console.log(this.selected_tiktok_shop)
            // const result = []
            //
            // const url = `https://ads.tiktok.com/api/oec_shopping/v1/oec/stat/post_campaign_list?locale=vi&language=vi&oec_seller_id=${this.selected_tiktok_shop.store_id}&aadvid=${this.selected_ads_account.advertiser_id}&bc_id=${this.selected_ads_account.bc_id}`
            // const data = {}
            // if (this.campaign_list_tab === 'PRODUCT_GMV_MAX') {
            //     Object.assign(data, {
            //         'query_list': [
            //             'campaign_opt_status',
            //             'campaign_name',
            //             'campaign_primary_status',
            //             'campaign_status',
            //             'campaign_budget',
            //             'campaign_budget_mode',
            //             'template_ad_start_time',
            //             'template_ad_end_time',
            //             'template_ad_roas_bid',
            //             'template_ad_schedule_type',
            //             'cost',
            //             'billed_cost',
            //             'onsite_roi2_shopping_sku',
            //             'cost_per_onsite_roi2_shopping_sku',
            //             'onsite_roi2_shopping_value',
            //             'onsite_roi2_shopping',
            //             'session_info',
            //             'campaign_no_bid_budget',
            //             'gmv_max_bid_type',
            //             'campaign_target_roi_budget_mode',
            //             'campaign_target_roi_budget'
            //         ],
            //         'start_time': moment(this.m__ranger_date[0]).format('YYYY-MM-DD'),
            //         'end_time': moment(this.m__ranger_date[1]).format('YYYY-MM-DD'),
            //         'order_field': 'cost',
            //         'order_type': 1,
            //         'page': 1,
            //         'campaign_status': [
            //             'no_delete'
            //         ],
            //         'keyword': '',
            //         'campaign_shop_automation_type': 2,
            //         'external_type_list': [
            //             '304',
            //             '307'
            //         ]
            //     })
            // }
            // if (this.campaign_list_tab === 'LIVE_GMV_MAX') {
            //     Object.assign(data, {
            //         'query_list': [
            //             'campaign_opt_status',
            //             'campaign_name',
            //             'campaign_primary_status',
            //             'campaign_status',
            //             'campaign_budget',
            //             'campaign_budget_mode',
            //             'tt_account_name',
            //             'tt_account_avatar_icon',
            //             'template_ad_identity_id',
            //             'template_ad_start_time',
            //             'template_ad_end_time',
            //             'template_ad_roas_bid',
            //             'template_ad_schedule_type',
            //             'cost',
            //             'billed_cost',
            //             'onsite_roi2_shopping_sku',
            //             'cost_per_onsite_roi2_shopping_sku',
            //             'onsite_roi2_shopping_value',
            //             'onsite_roi2_shopping',
            //             'session_info',
            //             'campaign_no_bid_budget',
            //             'gmv_max_bid_type',
            //             'campaign_target_roi_budget_mode',
            //             'campaign_target_roi_budget',
            //             'roi2_live_play_count',
            //             'cost_per_roi2_live_play_count',
            //             'roi2_live_effective_play_count',
            //             'cost_per_roi2_live_effective_play_count',
            //             'roi2_live_follows'
            //         ],
            //         'start_time': moment(this.m__ranger_date[0]).format('YYYY-MM-DD'),
            //         'end_time': moment(this.m__ranger_date[1]).format('YYYY-MM-DD'),
            //         'order_field': 'cost',
            //         'order_type': 1,
            //         'page': 1,
            //         'campaign_status': [
            //             'no_delete'
            //         ],
            //         'keyword': '',
            //         'campaign_shop_automation_type': 2,
            //         'external_type_list': [
            //             '305'
            //         ]
            //     })
            // }
            //
            // let response = await submitPostRequest({
            //     url,
            //     data,
            //     params: {},
            //     headers: {
            //         ...this.c__request_headers,
            //         'cookie': this.selected_ads_account.cookie
            //     }
            // })
            // // console.log('campaign stats', response)
            // while (response.data && response.data.data && response.data.data.data) {
            //     for (const item of response.data.data.data.table) {
            //         for (const key of Object.keys(item)) {
            //             if (['campaign_id'].includes(key)) continue
            //             if (typeof item[key] === 'string' && !isNaN(item[key])) {
            //                 item[key] = parseFloat(item[key])
            //             }
            //         }
            //     }
            //
            //     result.push(...response.data.data.data.table)
            //
            //     if (response.data.data.data.pagination.page >= response.data.data.data.pagination.page_count) break
            //
            //     data.page++
            //     response = await submitPostRequest({
            //         url,
            //         data,
            //         params: {},
            //         headers: {
            //             ...this.c__request_headers,
            //             'cookie': this.selected_ads_account.cookie
            //         }
            //     })
            // }
            //
            // return result

            const tiktok_account_id = this.$store.getters.tiktokBusiness.s__selected_tiktok_business.id
            const params = {
                advertiser_id: this.selected_ads_account.advertiser_id,
                store_ids: `["${this.selected_tiktok_shop.store_id}"]`,
                start_date: moment(this.m__ranger_date[0]).format('YYYY-MM-DD'),
                end_date: moment(this.m__ranger_date[1]).format('YYYY-MM-DD'),
                dimensions: `["campaign_id"]`,
                metrics: `["campaign_id", "operation_status", "campaign_name", "tt_account_name", "tt_account_profile_image_url", "identity_id", "bid_type", "schedule_type", "schedule_start_time", "schedule_end_time", "target_roi_budget", "max_delivery_budget", "roas_bid", "cost", "net_cost", "orders", "cost_per_order", "gross_revenue", "roi", "live_views", "cost_per_live_view", "10_second_live_views", "cost_per_10_second_live_view", "live_follows"]`,
                page: 1,
                page_size: 1000
            }
            let response = await gmvMaxGetReport(tiktok_account_id, params)
            // console.log('campaign stats', response)

            const report_data = []
            while (response && response.data && response.data.data && response.data.data.list && response.data.data.list.length) {
                report_data.push(...response.data.data.list.map(i => i.metrics))

                if (report_data.length >= response.data.data.page_info.total_number) break

                params.page++
                response = await gmvMaxGetReport(tiktok_account_id, params)
            }

            return report_data
        },

        async callGetCampaigns() {
            this.loading.get_ads_data = true
            this.campaign_list = []

            const tiktok_account_id = this.$store.getters.tiktokBusiness.s__selected_tiktok_business.id
            const params = {
                advertiser_id: this.selected_ads_account.advertiser_id,
                fields: ['roas_bid', 'budget'],
                page: 1,
                page_size: 100,
                filtering: {
                    gmv_max_promotion_types: [this.campaign_list_tab],
                    store_id: [this.selected_tiktok_shop.store_id]
                }
            }

            let response = await gmvMaxGetCampaigns(tiktok_account_id, params)
            // console.log(response)
            while (response && response.data && response.data.data && response.data.data.list && response.data.data.list.length) {
                this.campaign_list.push(...response.data.data.list)

                params.page++
                response = await gmvMaxGetCampaigns(tiktok_account_id, params)
            }

            // const report_params = {
            //     advertiser_id: this.selected_ads_account.advertiser_id,
            //     page: 1,
            //     page_size: 100,
            //     data_level: 'AUCTION_ADVERTISER',
            //     dimensions: ['advertiser_id'],
            //     report_type: 'TT_SHOP',
            //     start_date: moment(this.m__ranger_date[0]).format('YYYY-MM-DD'),
            //     end_date: moment(this.m__ranger_date[1]).format('YYYY-MM-DD'),
            //     metrics: ['spend', 'billed_cost']
            // }
            // const report_response = await getGmvMaxReportCampaigns(tiktok_account_id, report_params)
            // console.log(report_response)

            try {
                const campaigns_stats = await this.getCampaignStats()
                for (const campaign of this.campaign_list) {
                    const campaign_data = campaigns_stats.find(i => i.campaign_id === campaign.campaign_id)
                    if (campaign_data) {
                        Object.assign(campaign, campaign_data)

                        const number_keys = ['target_roi_budget', 'max_delivery_budget', 'roas_bid', 'cost', 'net_cost', 'orders', 'cost_per_order', 'gross_revenue', 'roi', 'live_views', 'cost_per_live_view', '10_second_live_views', 'cost_per_10_second_live_view', 'live_follows']
                        for (const key of number_keys) {
                            campaign[key] = Number(campaign[key])
                        }
                    }
                }
            } catch (e) {
                console.error(e)
            }

            this.campaign_list = JSON.parse(JSON.stringify(this.campaign_list))
            this.loading.get_ads_data = false
            console.log(JSON.stringify(this.campaign_list, null, 4))
        },

        async updateCampaignOperationStatus(campaign, status) {
            const tiktok_account_id = this.$store.getters.tiktokBusiness.s__selected_tiktok_business.id
            const data = {
                advertiser_id: this.selected_ads_account.advertiser_id,
                // campaign_id: campaign.campaign_id,
                campaign_ids: [campaign.campaign_id],
                operation_status: status
            }

            const response = await updateCampaignStatus(tiktok_account_id, data)
            console.log(response)
        },

        async showCampaignDetail(campaign) {
            this.selected_campaign = campaign
            this.show_dialog.campaign_detail = true
        },

        async confirmDeleteCampaign(campaign) {
            try {
                await this.$confirm(this.$t('gmv_max.delete_campaign_message'), 'Warning', {
                    confirmButtonText: this.$t('button.confirm'),
                    cancelButtonText: this.$t('button.cancel'),
                    type: 'warning'
                })
            } catch (e) {
                return
            }

            const tiktok_account_id = this.$store.getters.tiktokBusiness.s__selected_tiktok_business.id
            const data = {
                advertiser_id: this.selected_ads_account.advertiser_id,
                campaign_ids: [campaign.campaign_id],
                operation_status: 'DELETE'
            }

            await updateCampaignStatus(tiktok_account_id, data)
            await this.callGetCampaigns()
            this.$notify({
                title: 'Thông báo',
                message: 'Thao tác thành công',
                type: 'success'
            })
        },

        showUpdateCampaignComponent(campaign) {
            this.current_component = 'create'
            this.selected_campaign = campaign
        }
    }
}
</script>

<style lang="scss">
.hide-el-radio-label {
    .el-radio__label {
        display: none;
    }
}

.custom-el-tabs {
    .el-tabs__item {
        font-size: 16px;
    }
}

.active-circle {
    width: 5px;
    height: 5px;
    border-radius: 50%;
    background-color: #67c23a;
    display: inline-block;
    margin-right: 5px;
}

.inactive-circle {
    width: 5px;
    height: 5px;
    border-radius: 50%;
    background-color: #595959;
    display: inline-block;
    margin-right: 5px;
}

.gmv-max-table {
    .el-table__header {
        .el-table__cell {
            &.is-sortable {
                .cell {
                    justify-content: flex-start;
                }
            }
        }
    }
}
</style>
