<template>
    <div>
        <div>
            <!--            <pre>{{ overview_stats }}</pre>-->
            <!--            <pre>{{ overview_charts }}</pre>-->

            <div>
                <strong class="text-[16px]">{{ $t('common.overview') }}</strong>
            </div>
        </div>

        <div v-if="overview_stats" class="mt-[20px]">
            <div class="flex gap-[20px]">
                <div
                    v-for="metric in metric_list"
                    :key="metric.key"
                    class="p-[16px] h-[106px] border border-[#00000019] rounded-[4px] flex flex-col flex-1 gap-[6px]"
                >
                    <div>
                        <strong class="text-[14px] text-black">{{ metric.label }}</strong>
                    </div>
                    <div>
                        <strong class="text-[20px] text-black">
                            <template v-if="metric.has_currency">
                                {{ p__formatCurrency(overview_stats[metric.key]) }} {{ currency }}
                            </template>
                            <template v-else-if="metric.fixed">
                                {{
                                    overview_stats[metric.key] ? parseFloat(overview_stats[metric.key]).toFixed(metric.fixed) : 0
                                }}
                            </template>
                            <template v-else>
                                {{ p__formatCurrency(overview_stats[metric.key]) }}
                            </template>
                        </strong>
                    </div>
                </div>
            </div>
        </div>

        <div class="mt-[30px]">
            <Chart :options="chart_options"></Chart>
        </div>
    </div>
</template>

<script>

import { Chart } from 'highcharts-vue'

export default {
    name: 'GmvMaxCampaignMetrics',

    components: {
        Chart
    },

    props: {
        currency: null,
        overview_stats: null,
        overview_charts: null
    },

    data() {
        return {
            metric_list: [
                {
                    label: this.$t('gmv_max.cost'),
                    key: 'cost',
                    has_currency: true
                },
                {
                    label: this.$t('gmv_max.orders') + ' (SKU)',
                    key: 'orders'
                },
                {
                    label: this.$t('gmv_max.cost_per_order'),
                    key: 'cost_per_order',
                    has_currency: true
                },
                {
                    label: this.$t('gmv_max.gross_revenue'),
                    key: 'gross_revenue',
                    has_currency: true
                },
                {
                    label: 'ROI',
                    key: 'roi',
                    fixed: 2
                }
            ],

            chart_options: {
                chart: {
                    type: 'spline',
                    height: 280
                },
                title: {
                    text: ''
                },
                legend: {
                    enabled: true,
                    symbolRadius: 90,
                    symbolHeight: 10,
                    symbolWidth: 10,
                    align: 'left',
                    verticalAlign: 'top',
                    layout: 'horizontal',
                    x: 10,
                    y: 10
                },
                xAxis: {
                    categories: [],
                    crosshair: true,
                    minTickInterval: 6,
                    tickInterval: 1,
                    endOnTick: true,
                    startOnTick: true,
                    labels: {
                        step: 1
                    }
                },
                tooltip: {
                    pointFormatter: null,
                    shared: true
                },
                yAxis: [],
                series: [],
                noData: {
                    style: {
                        fontSize: '16px',
                        fontWeight: 'bold',
                        color: '#FF5733'
                    }
                }
            },

            hide_marker: {
                marker: {
                    enabled: false,
                    states: {
                        hover: {
                            enabled: false
                        }
                    }
                }
            },

            colors: [
                '#FF5733',
                '#8E44AD',
                '#2E86C1',
                '#28B463',
                '#F1C40F'
            ]
        }
    },

    watch: {
        overview_charts() {
            this.changeSeriesChart()
        }
    },

    mounted() {
        this.changeSeriesChart()
    },

    methods: {
        changeSeriesChart() {
            const labels = this.overview_charts.map(item => item.label)

            this.chart_options.xAxis = {
                categories: labels
            }
            this.chart_options.yAxis = [
                {
                    title: { text: null },
                    labels: { enabled: false },
                    tickAmount: 6
                },
                { visible: false }
            ]

            const series = []
            for (const [index, metric] of this.metric_list.entries()) {
                const row = {
                    name: metric.label,
                    yAxis: 0,
                    color: this.colors[index],
                    data: this.overview_charts.map(item => item[metric.key]),
                    ...this.hide_marker
                }
                series.push(row)
            }
            this.chart_options.series = series
        }
    }
}
</script>
